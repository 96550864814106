import React from 'react'
import "lazysizes"
import "bootstrap/dist/css/bootstrap.min.css"
import 'react-phone-input-2/lib/style.css'
import './src/styles/swiper.css'
import "./src/components/slick.min.css"
import "./src/components/slick-theme.min.css"
import './src/styles/variables.css'
import "./src/components/layout.css"
import "./src/components/responsive.css"
import './src/styles/global.css'
import './src/styles/global-responsive.css'

export function wrapPageElement({ element }) {
  return <div>{element}</div>
}

